import onKeybind from "./lib/onkeybind"

//
// ==UserScript==
// @name Show Whitespace
// @description Displays whitespace characters in code blocks when alt+shift+w is pressed.
// @namespace https://userscripts.kevincox.ca
// @version 1
// @grant none
// @include *
// ==/UserScript==

onKeybind("alt+W", _event => {
	var selectors = "pre";

	if (location.hostname == "github.com") selectors += ",.cm,.blob-code";

	[...document.querySelectorAll(selectors)].forEach(function process(el: ChildNode) {
		if (el.nodeName != "#text") {
			[...el.childNodes].forEach(process);
			return;
		}

		var highlight = el.ownerDocument!.createElement("span");
		highlight.textContent = el.textContent;
		let escaped = highlight.innerHTML;
		let highlighted = escaped
			.replace(/ +/g,  '<span style="background:hsla(  0, 50%, 50%, 0.3)">$&</span>')
			.replace(/\t+/g, '<span style="background:hsla(240, 50%, 50%, 0.4)">$&</span>');
		if (highlighted != escaped) {
			highlight.innerHTML = highlighted;
			el.parentNode?.replaceChild(highlight, el);
		}
	});
});
